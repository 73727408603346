.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** special sauce **/

/* For the Navbar */
nav {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-around;
  padding: 1rem;
}

nav ul {
  list-style: none;
  display: flex;
}

nav ul li {
  margin: 0 15px;
}

nav a {
  text-decoration: none;
  color: white;
}

nav a:hover {
  text-decoration: underline;
}

/* For the Dari Letter Spans */
span {
  font-size: 2em;
  margin: 0 5px;
}

/* For Question and Options */
p {
  font-size: 1.2em;
  margin-bottom: 1rem;
}

div[style="background-color: white;"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

div[style="background-color: white;"]:hover {
  background-color: #f0f0f0;
}

div[style="background-color: white;"]:active {
  background-color: #ccc;
}

.dariWordDisplay {
  font-size: 2em
}

.option {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  --bg-color: white;
  background-color: var(--bg-color);
}

.option-correct {
  --bg-color: #A8E6CF; /* Softer green */
}

.option-incorrect {
  --bg-color: #FF8A80; /* Softer red */
}

.text-container {
  background-color: rgba(255, 255, 255, 0.8); /* White background with 80% opacity */
  padding: 20px;
  border-radius: 5px;
  margin: 20px 10% 20px 10%;  /* Top, right, bottom, left margin */
  max-width: 80%; /* Maximum width */
  min-width: 60%; /* Minimum width */
}

body {
  background-image: url('../public/kabul.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* This will make sure the image stays in place while scrolling */
  background-repeat: no-repeat;
}

.englishWordDisplay {
  min-height: 1.5em;  /* Adjust based on your actual line-height */
  /* other styles you may have */
}